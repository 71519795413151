// custom typefaces
// import 'typeface-montserrat'
// import 'typeface-merriweather'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider
